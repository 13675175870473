import * as React from 'react';
import { Flex, Container, Heading, Text } from '@chakra-ui/react';
import Layout from '../components/layout';

const Page404 = () => {
  return (
    <Layout title="Not found" description="404 Page not found">
      <Container maxWidth="container.xl">
        <Flex
          direction="column"
          align="center"
          justify="center"
          minHeight="50vh"
        >
          <Heading fontSize="9xl">404 Page Not found</Heading>
          <Text fontSize="4xl">
            we couldn’t find what you were looking for.
          </Text>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Page404;
